import * as React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main'
import Success from './pages/Success'
import Failure from './pages/Failure'
import Rules from './pages/Rules'
import Conditions from './pages/Conditions'
import { Paper } from '@mui/material';
import bg from './resource/images/bg.jpg';

function App() {
  return (
    <Router />
  );
}

function Router() {
  return (
    <main className='Main'>
      <Paper style={{display: 'flex', backgroundImage: `url(${bg})`, minHeight: '100vh', width: '100vw', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <Pages />
      </Paper>
    </main>
  )
}

function Pages() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="app" element={<Main />} />
          <Route path="success" element={<Success />} />
          <Route path="fail" element={<Failure />} />
          <Route path="rules" element={<Rules />} />
          <Route path="conditions" element={<Conditions />} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
