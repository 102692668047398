
import $ from 'jquery'; 
import React from 'react';
import { useState, useEffect } from "react";
import { Box, Button, Link, CircularProgress } from '@mui/material';
import { createTheme } from '@mui/system';
import gplogo from '../resource/images/google_play_logo.png';
import telegram from '../resource/images/telegram.png';
import cardback from '../resource/images/cardback.png';

const theme = createTheme({
    palette: {
      background: {
        paper: '#d2fe5a',
      },
      text: {
        primary: '#173A5E',
        secondary: '#d2fe5a',
        white: '#fff',
      },
      action: {
        active: '#001E3C',
      },
      success: {
        dark: '#009688',
      },
      button: {
        primary: '#fff'
      }
    },
    opt: {
      text: {
        color: '#fff',
      }
    }
  });

export default function Main() {
  const [amount, setAmount] = useState(100);
  const [result, setResult] = useState("");

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  }

  useEffect(() => {
    var elem = document.getElementById('payeer')
    if (elem != null) {
      elem.submit();
    }
  }, [result]);

  const handleSubmit = (e) => {
      e.preventDefault();
      const form = $(e.target);
      $.ajax({
          type: "POST",
          url: form.attr("action"),
          data: form.serialize(),
          success(data) {
              setResult(data);  
          },
      });
  };
  
  return(
    <Box style={{width: '100vw', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', minHeight: '100vh'}}>
      <div style={{alignSelf: "center", color: theme.palette.text.white, textAlign: 'center', fontSize: '16px', marginTop: '10px', marginLeft: '10%', marginRight: '10%'}}>
        Добро пожаловать! На нашем сайте вы можете найти ссылку на приложение, где вы можете зарабатывать игровую валюту и обменивать её на настоящие деньги, генерируя и просматривая интересные картинки! Наше приложение/сайт не подразумевает того, чтобы пользователи что-либо покупали, или пополняли!
      </div>

      <div style={{color: theme.opt.text.color, fontSize: 16, fontFamily: 'Oswald', marginTop: '10px'}}>Присоединяйся<br/></div>
      <div style={{color: theme.opt.text.color, fontSize: 16, fontFamily: 'Oswald'}}>и<br/></div>
      <div style={{color: theme.opt.text.color, fontSize: 16, fontFamily: 'Oswald'}}>зарабатывай!</div>
      
      <Box style={{width: 250, height: 250, alignSelf: 'center', marginTop: 20, position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <img src={cardback} style={{width: 250, height: 250, position: 'absolute',  alignSelf: 'center'}}></img>
      <CircularProgress style={{position: 'absolute'}}/>
      </Box>
      
      <div style={{color: theme.opt.text.color, fontSize: 14, fontFamily: 'Oswald', textAlign: 'center'}}>Получай уникальные карточки и обменивай их на реальные деньги!</div>
      <div style={{color: theme.opt.text.color, fontSize: 14, fontFamily: 'Oswald'}}>Хочешь попробовать? Скачивай!</div>

      <Link href="https://play.google.com/store/apps/details?id=com.neuromoney.play&hl=ru&gl=US" style={{marginTop: 20}}>
          <Button 
              style={{backgroundColor:theme.palette.button.primary}}
              component="label"
              variant="text"
              startIcon={<img src={gplogo} style={{width: 200, height: 40, objectFit: 'cover'} } alt='googlePlay'/>}/>
      </Link>

      {/* <form
            id="myForm"
            action="https://neuromoney.fun/transaction"
            method="post"
            onSubmit={(event) => handleSubmit(event)}
        >         
        <div style={{display: 'flex', flexDirection: 'column', width: '220px', marginTop: '10px'}}>
          <div style={{display: 'flex', flexDirection: 'row', width: '220px', justifyContent: 'center', alignItems: 'center'}}>
            <input type="number" id="amount" name="m_amount" onChange={handleAmountChange} style={{width: '145px', height: '40px', borderRadius: '5px', paddingLeft: '10px'}} 
                value={amount}/>
            <select id="currency" name="m_curr" required style={{width: '70px', height: '45px', borderRadius: '5px'}}>
                <option value="RUB">RUB</option>
                <option value="USD">USD</option>
            </select>
          </div>
          <input style={{width: '220px', height: '52px', borderRadius: '5px', backgroundColor: '#fff', border: 'none', marginTop: '10px'}} type="submit" name="m_process" value="Пополнить кошелек" />
        </div>     
      </form>
      <div dangerouslySetInnerHTML={{ __html: result }} /> */}

      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '100%', alignSelf: 'end'}}>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
          <Link href="rules" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}> 
            <div style={{color: theme.palette.text.white, fontSize: '14px'}}>
                Правила
            </div>
          </Link>
          <Link href="conditions" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}> 
            <div style={{color: theme.palette.text.white, fontSize: '14px'}}>
              Политика конфиденциальности
            </div>
          </Link>
        </div>
        
        
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px'}}>
            <div style={{color: theme.palette.text.white, fontSize: '14px'}}>Контакты</div>
            <Link href="https://telegram.me/Father_F" style={{marginTop: '10px'}}>
              <img src={telegram} style={{height: '25px', width: '25px'}}/>
            </Link>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '10px'}}>
              <div style={{color: theme.palette.text.white}}>Почта:</div>
              <Link href="mailto:ttminert@gmail.com" style={{color: theme.palette.text.white, marginLeft: '10px'}}> 
                ttminert@gmail.com
              </Link>
            </div>
        </div>
      </div>


    </Box>
    
  )
  
}