
import { Box, Button, Link } from '@mui/material';
import { createTheme, maxWidth } from '@mui/system';

const theme = createTheme({
    palette: {
      background: {
        paper: '#d2fe5a',
      },
      text: {
        primary: '#173A5E',
        secondary: '#d2fe5a',
        white: '#fff'
      },
      action: {
        active: '#001E3C',
      },
      success: {
        dark: '#009688',
      },
      button: {
        primary: '#fff'
      }
    },
    opt: {
      text: {
        color: '#fff',
      }
    }
  });

export default function Success() {

    return(
      <Box style={{width: '100vw', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
        {/* <div style={{color: theme.palette.text.white, fontSize: '20px', marginTop: '20px'}}>
          1.  Запрещается использовать какие либо средства автоматизации для получения выгоды;<br/>
          2. Запрещается злоупотреблять системой, пользоваться багами приложения в корыстных целях;<br/>
          3. Запрещается оскорблять администрацию и других участников проекта;<br/>
          4. Запрещается разжигать ненависть и межнациональные конфликты.<br/>
        </div> */}
        <Box style={{
           color: theme.palette.text.black, 
           width: '60vw', 
           fontSize: '18px',
           background: theme.palette.text.white,
           padding: 30,
           margin: 30,
           borderRadius: 30,
        }}>
          <h1>NeuroMoney Privacy Policy</h1>
          <h2>Политика конфиденциальности</h2>
          Настоящая политика конфиденциальности распространяется на приложение
          NeuroMoney (далее именуемое «Приложение») для мобильных устройств, созданное
          Виктором (далее именуемым «Поставщик услуг») в качестве сервиса с поддержкой
          рекламы. Этот сервис предназначен для использования «КАК ЕСТЬ».<br/><br/>

          Сбор и использование информации
          Приложение собирает информацию, когда вы его загружаете и используете. Эта
          информация может включать такие данные, как
          ● Адрес интернет-протокола вашего устройства (например, IP-адрес)
          ● Страницы приложения, которые вы посещаете, время и дата вашего
          посещения, время, проведенное на этих страницах
          ● Время, затраченное на обработку Заявки
          ● Операционная система, которую вы используете на своем мобильном
          устройстве<br/><br/>

          Приложение не собирает точную информацию о местоположении вашего мобильного
          устройства.<br/><br/>

          Поставщик услуг может использовать предоставленную вами информацию, чтобы
          время от времени связываться с вами и предоставлять вам важную информацию,
          необходимые уведомления и маркетинговые акции.<br/><br/>

          Для более удобного использования приложения поставщик услуг может потребовать от
          вас предоставления определенной информации, позволяющей установить вашу
          личность, включая, помимо прочего, идентификатор пользователя, IP-адрес
          пользователя. Информация, которую запрашивает поставщик услуг, будет храниться и
          использоваться в соответствии с настоящей политикой конфиденциальности.<br/><br/>

          Доступ третьей стороны
          Только агрегированные, обезличенные данные периодически передаются внешним
          сервисам, чтобы помочь поставщику услуг улучшить приложение и свои услуги.
          Поставщик услуг может передавать вашу информацию третьим лицам способами,
          описанными в настоящем заявлении о конфиденциальности.<br/><br/>

          Пожалуйста, обратите внимание, что Приложение использует сторонние сервисы, у
          которых есть собственная политика конфиденциальности в отношении обработки
          данных. Ниже приведены ссылки на политику конфиденциальности сторонних
          поставщиков услуг, используемых Приложением:<br/>
          ● Сервисы Google Play<br/>
          ● Google Analytics для Firebase<br/><br/>

          Поставщик Услуг может раскрывать Предоставленную Пользователем и Автоматически
          Собираемую Информацию:<br/>
          ● в соответствии с требованиями закона, например, для выполнения повестки в
          суд или аналогичного судебного процесса;<br/>
          ● если они добросовестно считают, что раскрытие информации необходимо для
          защиты их прав, вашей безопасности или безопасности других людей,
          расследования мошенничества или выполнения запроса государственных
          органов;<br/>
          ● с их доверенными поставщиками услуг, которые работают от их имени, не
          используют самостоятельно информацию, которую мы им раскрываем, и
          согласились соблюдать правила, изложенные в настоящем заявлении о
          конфиденциальности.<br/><br/>

          Права на отказ от участия
          Вы можете легко остановить сбор информации приложением, удалив его. Вы можете
          использовать стандартные процессы удаления, которые могут быть доступны на
          вашем мобильном устройстве или на рынке мобильных приложений или в сети.<br/><br/>

          Политика Хранения данных
          Поставщик услуг будет хранить предоставленные вами данные до тех пор, пока вы
          используете Приложение, и в течение разумного периода времени после этого. Если
          вы хотите, чтобы они удалили предоставленные вами данные, которые вы
          предоставили через Приложение, пожалуйста, свяжитесь с ними по адресу
          timethefather@gmail.com, и они ответят вам в течение разумного периода времени.<br/><br/>

          Дети
          Поставщик услуг не использует Приложение для сознательного сбора данных или
          продажи товаров детям младше 13 лет.<br/><br/>

          Приложение не предназначено для лиц младше 13 лет. Поставщик услуг не собирает
          намеренно информацию, позволяющую установить личность, от детей младше 13 лет.
          Если Поставщик услуг обнаружит, что ребенок младше 13 лет предоставил личную
          информацию, Поставщик услуг немедленно удалит ее со своих серверов. Если вы
          являетесь родителем или опекуном и вам известно, что ваш ребенок предоставил нам
          личную информацию, пожалуйста, свяжитесь с Поставщиком услуг
          (timethefather@gmail.com), чтобы он мог принять необходимые меры.<br/><br/>

          Безопасность
          Поставщик услуг заботится о сохранении конфиденциальности вашей информации.
          Поставщик услуг обеспечивает физическую, электронную и процедурную защиту
          информации, которую он обрабатывает и хранит.<br/><br/>

          Изменения
          Настоящая Политика конфиденциальности может время от времени обновляться по
          любой причине. Поставщик услуг уведомит вас о любых изменениях в Политике
          конфиденциальности, обновив эту страницу с новой Политикой конфиденциальности.
          Вам рекомендуется регулярно просматривать настоящую Политику
          конфиденциальности на предмет любых изменений, поскольку дальнейшее
          использование считается одобрением всех изменений.<br/><br/>

          Настоящая политика конфиденциальности действует с 18 января 2025 года<br/><br/>

          Ваше Согласие
          Используя Приложение, вы соглашаетесь на обработку ваших данных в соответствии с
          настоящей Политикой конфиденциальности с внесенными нами изменениями.<br/><br/>

          Связаться с нами
          Если у вас есть какие-либо вопросы о конфиденциальности при использовании
          Приложения или о правилах его использования, пожалуйста, свяжитесь с поставщиком
          услуг по электронной почте timethefather@gmail.com.
        </Box>
        
        <Link href="/" style={{margin: 20}}>
            <Button 
                style={{backgroundColor:theme.palette.button.primary}}
                component="label"
                variant="text"> На главную </Button>
        </Link>
      </Box>
    )
}